import React from 'react';

function PDFButton() {
  const pdfUrl = "https://drive.google.com/file/d/1zuWSMe6GYAWFwkK20dNFEahkJ6t7Mph-/view?usp=drive_link";

  return (
    <div style={{ textAlign: 'center', marginTop: '150px' }}>
      <h1>Access the PDF</h1>
      <button
        onClick={() => window.open(pdfUrl, '_blank')}
        style={{
          padding: '10px 20px',
          fontSize: '16px',
          backgroundColor: '#4CAF50',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
        }}
      >
        Go to PDF
      </button>
    </div>
  );
}

export default PDFButton;
