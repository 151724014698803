import React, { useState, useEffect } from 'react';
import styles from './acco.module.css';
import Faq from 'react-faq-component';
import axios from 'axios';
import Modal from './Modal'; // Import the Modal component
import { UserAuth } from '../../context/AuthContext';

const Acco = () => {
  axios.defaults.xsrfCookieName = "csrftoken";
  axios.defaults.xsrfHeaderName = "X-CSRFToken";
  const { user } = UserAuth();
  const [formData, setFormData] = useState({
    name: user.displayName,
    email: user.email,
    phone: '',
    gender: '',
    dob: '',
    city: '',
    aadhar: '',
    no_of_male: '',
    no_of_female: '',
    checkin: '',
    checkout: '',
  });

  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const [active, setActive] = useState('Introduction');
  const [activeIndex, setActiveIndex] = useState(null);
  const [isRegistered, setIsRegistered] = useState(false); // Track registration status

  const email = user?.email;

  useEffect(() => {
    if (email) {
      axios.get('https://techfest.org/api/check_registration/', { params: { email: email } })
        .then((response) => {
          console.log('Full response:', response); // Log the entire response object
          console.log('Registration status:', response.data); // Log the boolean directly
          setIsRegistered(response.data); // response.data is a boolean (true/false)
        })
        .catch((error) => {
          console.error('Error checking registration status:', error);
        });
    }
  }, [email]);





  const openCity = (activeName) => {
    setActive(activeName);
  };

  const handleClick = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios.post('https://techfest.org/api/acco_reg/', formData)
      .then((response) => {
        setMessage('Registration successful! Redirecting to the payment page...');
        console.log(response.data);
        setIsRegistered(true); // Set registration status to true
        setShowModal(false); // Close the modal
        window.location.href = 'https://techfest.org'; // Redirect to payment page
      })
      .catch((error) => {
        setMessage('Registration failed. Please try again.');
        console.error(error.response ? error.response.data : error.message);
      });
  };

  const handleButtonClick = () => {
    if (isRegistered) {
      // If the user is registered, redirect to payment page
      window.location.href = 'https://techfest.org';
    } else {
      // Otherwise, open the registration modal
      setShowModal(true);
    }
  };


  const styles1 = {
    bgColor: 'none',
    titleTextColor: 'white',
    rowTitleColor: 'white',
    arrowColor: 'white',
    rowContentColor: 'white',
    marginBottom: '20px',
    maxWidth: '80%',
    margin: 'auto',
    rowContentMarginBottom: '0',
  }

  const data = {
    rows: [
      {
        title: "What if I come with my family?",
        content: (
          <>
            1.Complete all the required registrations and payment.<br />
            2.  Send an email with your Acco ID on hospitality@techfest.org and details of all the Family members (including Name, Gender, Relation)<br />
            **Do it within one week of your payment
          </>
        ),
      },
      {
        title: "How to avail accommodation?",
        content: (
          <>
            1. Click on Register now <br />
            2. Complete the payment procedure. <br /> Confirmation will be sent
            to you<br />4. Report at accommodation desk
          </>
        ),
      },
      {
        title: "What is the payment procedure?",
        content:
          "The payment procedure will be online. The accommodation charges are provided under the tab Accommodation Charges on the hospitality page. You will have to report at the accommodation desk near the Old Swimming Pool with the email printout and the mandatory documents. Failing to bring any of the documents may lead to the cancellation of your accommodation.",
      },
      {
        title: "How do I know that my payment is confirmed?",
        content:
          "Confirmation is not confirmed till a confirmation mail is not received in 24 hours. If not received confirmation in 24 hours, then mail your transaction ID, Techfest ID, name, amount to himanshu@techfest.org with the subject as TFID :: Confirmation mail not received.",
      },
      {
        title: "Documents that guests should carry?",
        content: (
          <>
            1. Any valid Govt photo ID <br />
            2. Print out of Email confirmation <br />
            3. Gate pass <br />
            4. Valid College ID for participants
          </>
        ),
      },
      {
        title: "Should I carry my ID card with me?",
        content:
          "It is mandatory for all guest participants to carry college IDs. This is for your own convenience. Moreover, you will be asked to produce your college ID cards at the time of allotment of rooms.",
      },
      {
        title: "What are the Accommodation charges?",
        content:
          "The accommodation charges and other necessary details regarding the payment will be available under the tab Accommodation.",
      },
      {
        title: "What kind of accommodation is provided?",
        content:
          "Accommodation is provided on a shared basis inside campus hostels or outside hotels. One mattress, and enough space for keeping luggage, and other essential commodities will be provided. Girls and boys will be accommodated separately. The number of guests in a room will be decided by Techfest and will be allotted by Techfest team.",
      },
      {
        title: "Does the accommodation facilities include food facilities?",
        content:
          "No, the accommodation charges do not include food facilities. Guests can purchase their meals from the food court, night cafeteria, or private hostel canteens or hostel messes at just INR 50 per meal.",
      },
      {
        title: "What are the food facilities inside IIT Bombay?",
        content:
          "For all our guests and guests, we set up a huge cafeteria serving a variety of cuisines satisfying the needs of every palate. In addition, you can also eat in the many canteens, messes, and restaurants inside the campus.",
      },
      {
        title: "Can I enter IIT Bombay campus at any time?",
        content:
          "You can enter IIT Main gate anytime by showing a valid photo ID proof during 6am to 10pm, however, you need to have accommodation inside the campus to stay in the campus after 10pm.",
      },
      {
        title: "Can we get accommodation on the spot at Techfest?",
        content:
          "No confirmation of accommodation will be provided. It is completely subject to the availability of rooms.",
      },
    ],
  };

  return (
    <>
      <div className={styles.firstview}>
        {/* <div className={styles.accobanner}></div> */}
        {/* <div
          className={styles.bar_oii1}
          onClick={handleButtonClick} // Apply onClick here
        >
          <div className={`${styles.bar} ${active === 'Register' ? styles.baractive : ''}`}></div>
          <div className={`${styles.bar_item} ${active === 'Register' ? styles.active : ''}`} style={{ textAlign: 'center' }}>
            {isRegistered ? 'SOLD OUT' : 'SOLD OUT'}
          </div>
        </div> */}
        <h1>SOLD OUT ACCOMMODATION</h1>

      </div>
      <div className={styles.mainContainer}>
        <div className={styles.content1}>
          <div className={styles.bar_oii} onClick={() => openCity('Introduction')}>
            <div className={`${styles.bar} ${active === 'Introduction' ? styles.baractive : ''}`}></div>
            <div className={`${styles.bar_item} ${active === 'Introduction' ? styles.active : ''}`}>
              About Us
            </div>
          </div>
          <div className={styles.bar_oii} onClick={() => openCity('Accommodation')}>
            <div className={`${styles.bar} ${active === 'Accommodation' ? styles.baractive : ''}`}></div>
            <div className={`${styles.bar_item} ${active === 'Accommodation' ? styles.active : ''}`}>
              Accommodation
            </div>
          </div>
          <div className={styles.bar_oii} onClick={() => openCity('Instructions')}>
            <div className={`${styles.bar} ${active === 'Instructions' ? styles.baractive : ''}`}></div>
            <div className={`${styles.bar_item} ${active === 'Instructions' ? styles.active : ''}`}>
              Instructions
            </div>
          </div>
          <div className={styles.bar_oii} onClick={() => openCity('FAQs')}>
            <div className={`${styles.bar} ${active === 'FAQs' ? styles.baractive : ''}`}></div>
            <div className={`${styles.bar_item} ${active === 'FAQs' ? styles.active : ''}`}>
              FAQs
            </div>
          </div>
          <div className={styles.bar_oii} onClick={() => openCity('Reaching IITB')}>
            <div className={`${styles.bar} ${active === 'Reaching IITB' ? styles.baractive : ''}`}></div>
            <div className={`${styles.bar_item} ${active === 'Reaching IITB' ? styles.active : ''}`}>
              Reaching IITB
            </div>
          </div>
          <div className={styles.bar_oii} onClick={() => openCity('Contact Us')}>
            <div className={`${styles.bar} ${active === 'Contact Us' ? styles.baractive : ''}`}></div>
            <div className={`${styles.bar_item} ${active === 'Contact Us' ? styles.active : ''}`}>
              Contact Us
            </div>
          </div>

        </div>
        <div className={styles.information}>
          <div id="Introduction" className={`${styles.info} ${active === 'Introduction' ? styles.show : styles.hide}`}>
            <div className={styles.info_tab}>
              <div className={styles.info_data}>
                <h2>About Us</h2>
                <p>Techfest has been an example in achieving huge feats with unparalleled figures ever since its inception in 1998. Techfest has grown in stature in terms of its content, infrastructure and logistics. The overwhelming crowd of such a high magnitude and a world-class technological display along with a tinge of enjoyment has made our dream a technological extravaganza. With such vastness and diversity, the hospitality of the guests is of paramount importance.</p>
                <p>We, at Techfest, constantly strive towards the satisfaction of everyone. We shall leave no stone unturned in fulfilling the needs of a secure accommodation away from home. Along with accommodation facilities for our participants, we also set up a cafeteria serving a variety of cuisines satisfying the needs of every palate. We strive to make your stay comfortable and your experience, a memorable one. Hospitality management would be one of the prime focuses of Team Techfest 2024-25. Hope to see you at Techfest 2024-25. We will be more than happy to receive your suggestions and queries.</p>
                <h2>Hospitality Walk Through</h2>
                <p>Entry will be through IIT Bombay main gate only after producing ticket ID, after which you will be given coloured band. Report at hospitality desk. Produce Your : Ticket ID, Gate Pass, Your college ID card & Photocopy of Govt. ID card. You will be given the keys of your allotted rooms along with the accommodation receipt.</p>
              </div>
            </div>
          </div>
          <div id="Instructions" className={`${styles.info} ${active === 'Instructions' ? styles.show : styles.hide}`}>
            <div className={styles.info_tab}>
              <div className={styles.info_data}>
                <ol>
                  <li>
                    <p>
                      All guests carrying electronic items of any kind will have to
                      declare them at the IIT Bombay main gate through a 'Gate Pass'. The
                      belongings will also be checked on the way out of IIT Bombay along
                      with the 'Gate Pass', failing to do so will result in the belongings
                      being impounded.
                    </p>
                  </li>
                  <li>
                    <p>
                      All guests will be provided with a mattress. Techfest will not
                      provide mattress cover, blankets, or pillows. The guests are
                      encouraged to arrange them on their own (if required), since the
                      weather might get cold during the night.
                    </p>
                  </li>
                  <li>
                    <p>
                      Any commodities issued to the guests would have to be returned in
                      sound condition to the organizers during check-out.
                    </p>
                  </li>
                  <li>
                    <p>
                      Random checks would be made to avoid any illegal stay at the campus.
                      Any team failing to produce their electronic/physical receipts of
                      accommodation would be heavily fined and disqualified.
                    </p>
                  </li>
                  <li>
                    <p>
                      Entry will be only through the 'Main Gate' of IIT Bombay. All other
                      gates will be closed for entry.
                    </p>
                  </li>
                  <li>
                    <p>
                      All guests are required to carry their valid government photo ID
                      proofs at all times. In addition, the student participants are also
                      required to carry their valid College photo ID card. Any guest
                      failing to produce their ID card will not be permitted inside the
                      campus during Techfest 2024.
                    </p>
                  </li>
                  <li>
                    <p>
                      Alcohol, drugs, sharp objects, and explosives of any kind are
                      strictly prohibited inside the campus. Any other item if deemed
                      unsafe will be prohibited. The decision of Security and Techfest
                      team will be final in case of any disputes.
                    </p>
                  </li>
                  <li>
                    <p>
                      No outside vehicles will be allowed into the campus during Techfest
                      2024.
                    </p>
                  </li>
                  <li>
                    <p>
                      All guests are required to maintain the decorum and cleanliness of
                      the campus, and follow the rules of the campus at all times.
                    </p>
                  </li>
                  <li>
                    <p>
                      Techfest 2024 and IIT Bombay will not be responsible for any mishaps
                      that occur through the duration of stay for Techfest 2024.
                    </p>
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div id="Accommodation" className={`${styles.info} ${active === 'Accommodation' ? styles.show : styles.hide}`}>
            <div className={styles.info_tab}>
              <div className={styles.info_data}>
                <div className={styles.infoContainer} style={{ width: "95%", margin: "0 2.5%" }}>
                  <div className={styles.section}>
                    <h2>Accommodation Charges</h2>
                    <p>
                      Accommodation charges are INR 3099 <s>3599</s> (Early Bird Offer (Valid till 24th Nov, 11:59PM)) per candidate for 5 days. Maximum of 5 nights stay allowed (16th Dec 6 AM - 20th Dec 10 AM). It does not include food facility. Guests can purchase their meals from the food court, night cafeteria, or private hostel canteens or hostel messes at subsidized rates.
                    </p>
                  </div>

                  <div className={styles.section}>
                    <h2>Timing</h2>
                    <p>Check-in: 6:00 AM to 10:00 PM on your check-in date</p>
                  </div>

                  <div className={styles.section}>
                    <h2>Cancellation Policy</h2>
                    <p>
                      Confirmed Accommodation can be cancelled through email only. Send an email with your Acco ID and the number of members for whom accommodation has to be cancelled, to hospitality@techfest.org. The subject of the email should be "Cancellation of Accommodation". For any cancellations before the deadline, 70% of the total amount shall be refunded within 1 month after the festival. There shall be no refunds for cancellation after the deadline. Deadline for cancellation: 1st December 2024.
                    </p>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div id="FAQs" className={`${styles.info} ${active === 'FAQs' ? styles.show : styles.hide}`}>
            <div className={styles.info_tab}>
              <div className={styles.info_data}>
                <Faq
                  data={data}
                  styles={styles1}
                  activeIndex={activeIndex}
                  onToggle={handleClick}
                />
              </div>
            </div>
          </div>
          <div id="Reaching IITB" className={`${styles.info} ${active === 'Reaching IITB' ? styles.show : styles.hide}`}>
            <div className={styles.info_tab}>
              <div className={styles.info_data}>
                <div style={{ padding: '20px', lineHeight: '1.6' }}>
                  <h2>Travelling in Mumbai</h2>
                  <p>
                    Mumbai offers various modes of transport such as taxis, auto rickshaws, local trains, and BEST buses. IIT Bombay is located in Powai, an eastern suburb in the North-Eastern part (Central Railway Line) of Mumbai.
                  </p>

                  <h3>Local Train Routes</h3>
                  <ul>
                    <li><strong>Western Railway:</strong> Churchgate to Borivali/Virar and return.</li>
                    <li><strong>Central Railway:</strong> Mumbai CST to Karjat/Kasara and return.</li>
                    <li><strong>Harbour Route:</strong> Mumbai CST to Andheri and return.</li>
                    <li><strong>New Bombay Route:</strong> Mumbai CST to Vashi/Panvel and return.</li>
                  </ul>

                  <h3>Nearest Local Train Station to IIT Bombay</h3>
                  <p>
                    <strong>Kanjur Marg:</strong> Closest local train station to IIT Bombay, located on the Central Railway line.
                    <br />
                    An auto rickshaw from Kanjur Marg station to IIT Bombay Main Gate costs approximately ₹45.
                  </p>

                  <h3>Important Railway Stations</h3>
                  <ul>
                    <li><strong>Chhatrapati Shivaji Terminal (CST)</strong></li>
                    <li><strong>Churchgate</strong></li>
                    <li><strong>Mumbai Central</strong></li>
                    <li><strong>Dadar</strong></li>
                    <li><strong>Lokmanya Tilak Terminus (LTT)</strong></li>
                    <li><strong>Thane</strong></li>
                    <li><strong>Borivali</strong></li>
                    <li><strong>Andheri</strong></li>
                    <li><strong>Bandra</strong></li>
                    <li><strong>Kalyan</strong></li>
                  </ul>

                  <h3>Nearest Stations to IIT Bombay</h3>
                  <ol>
                    <li><strong>Thane:</strong> Nearest station to IIT Bombay.</li>
                    <li><strong>Lokmanya Tilak Terminus (LTT):</strong> Near Kurla.</li>
                    <li><strong>Dadar:</strong> Third closest station.</li>
                  </ol>

                  <h3>Modes of Transport</h3>
                  <p>
                    <strong>Taxis:</strong> Available throughout Mumbai.
                    <br />
                    <strong>Cool Cabs:</strong> AC taxis for a more comfortable travel experience.
                  </p>
                  <p>
                    <strong>Auto Rickshaws:</strong> Operate between Bandra-Borivali and Sion-Mulund.
                    <br />
                    Preferred if you have heavy luggage, which might be inconvenient on local trains and buses.
                  </p>

                  <h3>Travel Tips</h3>
                  <ul>
                    <li>Trains and buses should be preferred if you carry less baggage with you, say a small bag per person.</li>
                    <li>Please beware of pickpockets in locals and BEST buses.</li>
                    <li>Southbound trains on all the Local train lines are more crowded in the morning and should be avoided if the passenger is carrying baggage.</li>
                    <li>Similarly, the northbound trains in the evening are more crowded.</li>
                    <li>With respect to mornings at Kanjurmarg station, traveling from Mumbai CST, Dadar, or Kurla would be less crowded than traveling from Kalyan or Thane.</li>
                    <li>For buses/auto-rickshaws, the destination should be stated as "IIT Main Gate, Powai".</li>
                    <li>Please download the m-indicator mobile application for hands-on maps & routes of Mumbai.</li>
                  </ul>

                  <h3>Techfest Registration Guidelines</h3>
                  <ul>
                    <li>Every team has to register online on the official Techfest website for the competition.</li>
                    <li>A Team ID will be allocated to the team on registration, which shall be used for future references.</li>
                    <li>The decision of the organizers or judges shall be treated as final and binding on all.</li>
                    <li>No responsibility will be held by Techfest, IIT Bombay for any late, lost, or misdirected entries.</li>
                    <li>Note that at any point in time, the latest information will be that which is on the website. However, registered participants will be informed through mail about any changes.</li>
                    <li>All modes of official communication will be through the Techfest e-mail. Participants are advised to keep track of all folders in their e-mail accounts.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div id="Contact Us" className={`${styles.info} ${active === 'Contact Us' ? styles.show : styles.hide}`}>
            <div className={styles.info_tab}>
              <div className={styles.info_data}>
                <div style={{ padding: '20px', lineHeight: '1.6' }}>
                  <h3 style={{ marginBottom: '4vh'}}>Contact Information</h3>
                  <div style={{ display: 'flex', flexWrap: 'wrap' }} className={styles.contacts}>
                    
                    
                    <div className={styles.contact}>
                      <strong>Manish Chahar</strong>
                      <br />
                      <span>Phone:</span> +91 97727 90419
                      <br />
                      <span>Email:</span> <a href="mailto:manishh.techfest@gmail.com">manishh.techfest@gmail.com</a>
                    </div>

                    <div className={styles.contact}>
                      <strong>Keshav Sharma</strong>
                      <br />
                      <span>Phone:</span> +91 93146 66614
                      <br />
                      <span>Email:</span> <a href="mailto:keshavsharma.techfest@gmail.com">keshavsharma.techfest@gmail.com</a>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onClose={() => setShowModal(false)}>
        <div className={styles.info_data}>
          <form onSubmit={handleSubmit} className={styles.form}>
            <input placeholder='Name' type='text' name="name" value={formData.name} onChange={handleInputChange} required />
            <input placeholder='Email ID' type='email' name="email" value={formData.email} onChange={handleInputChange} required />
            <input
              placeholder="Phone Number"
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              pattern="\d{10}"
              title="Phone number must be 10 digits long."
              required
            />

            <div className={styles.inputDiv}>
              <p className={styles.genderOption}>Gender</p>
              <div className={styles.genderOpt}>
                <label>
                  <input type="radio" name="gender" value="male" required onChange={handleInputChange} />
                  Male
                </label>
                <label>
                  <input type="radio" name="gender" value="female" onChange={handleInputChange} />
                  Female
                </label>
                <label>
                  <input type="radio" name="gender" value="other" onChange={handleInputChange} />
                  Other
                </label>
              </div>
            </div>
            <input type="text" name="dob" placeholder="Date of Birth (DD-MM-YYYY)" value={formData.dob} pattern="\d{2}-\d{2}-\d{4}" required onChange={handleInputChange} />
            <input type='text' placeholder='City' name='city' value={formData.city} required onChange={handleInputChange} />
            <input
              type="text"
              name="aadhar"
              value={formData.aadhar}
              placeholder="AADHAR Number"
              onChange={handleInputChange}
              pattern="\d{12}"
              title="AADHAR number must be exactly 12 digits long."
            />

            <input type='number' name='no_of_male' placeholder='Number of males' value={formData.no_of_male} required onChange={handleInputChange} />
            <input type='number' name='no_of_female' placeholder='Number of females' value={formData.no_of_female} required onChange={handleInputChange} />

            <label for="checkin">Checkin Date</label>
            <select name="checkin" id="checkin" value={formData.checkin} required onChange={handleInputChange}>
              <option value="" disabled selected>Select Checkin Date</option>
              <option value="2024-12-16">16th December 2024</option>
              <option value="2024-12-17">17th December 2024</option>
              <option value="2024-12-18">18th December 2024</option>
              <option value="2024-12-19">19th December 2024</option>
            </select>

            <label for="checkout">Checkout Date</label>
            <select name="checkout" id="checkout" value={formData.checkout} required onChange={handleInputChange}>
              <option value="" disabled selected>Select Checkout Date</option>
              <option value="2024-12-17">17th December 2024</option>
              <option value="2024-12-18">18th December 2024</option>
              <option value="2024-12-19">19th December 2024</option>
              <option value="2024-12-20">20th December 2024</option>
            </select>

            <button className={styles.registerButton}>Register</button>
            <br /><br />
          </form>
        </div>
      </Modal>
    </>
  );
};

export default Acco;
