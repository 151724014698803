import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import { QRCodeCanvas } from "qrcode.react";
import { UserAuth } from "../../context/AuthContext";
import styles from "./passes.module.css";
import down from "./assets/down.svg";
import logo from "./assets/logo.png";

const Passes = () => {
  const events = [
    {
      id: 0,
      name: "Main Gate Day 0",
      venue: "Main Gate",
      date: "16th December,2024",
      map: "https://maps.app.goo.gl/6wJ19uq4XQQtUc3z6"
    },
    {
      id: 1,
      name: "Main Gate Day 1",
      venue: "Main Gate",
      date: "16th December,2024",
      map: "https://maps.app.goo.gl/6wJ19uq4XQQtUc3z6"
    },
    {
      id: 2,
      name: "Main Gate Day 2",
      venue: "Main Gate",
      date: "16th December,2024",
      map: "https://maps.app.goo.gl/6wJ19uq4XQQtUc3z6"
    },
    {
      id: 3,
      name: "Main Gate Day 3",
      venue: "Main Gate",
      date: "16th December,2024",
      map: "https://maps.app.goo.gl/6wJ19uq4XQQtUc3z6"
    },
    {
      id: 4,
      name: "Technoholix",
      venue: "Student Activity Centre (SAC)",
      date: "19th December,2024",
      map: ""
    },
    // {
    //   id: 5,
    //   name: "Robowars Day 1",
    //   venue: "Student Activity Centre (SAC)",
    //   date: "17th December,2024",
    //   map: ""
    // },
    // {
    //   id: 6,
    //   name: "Robowars Day 2",
    //   venue: "Student Activity Centre (SAC)",
    //   date: "18th December,2024",
    //   map: ""
    // },

    
  ];

  const masterWorkshops = [
    {
      id: 7,
      name: "Machine Learning",
      venue: "LA 001",
      date: "16th & 17th December,2024",
      map: "https://maps.app.goo.gl/j9thgxyKJ2XbHc6x9"
    },
    {
      id: 9,
      name: "Web Development",
      venue: "LA 202",
      date: "16th & 17th December,2024",
      map: "https://maps.app.goo.gl/j9thgxyKJ2XbHc6x9"
    },
    {
      id: 10,
      name: "Artificial Intelligence",
      venue: "LA 001",
      date: "18th & 19th December,2024",
      map: "https://maps.app.goo.gl/j9thgxyKJ2XbHc6x9"
    },
    // {
    //   id: 11,
    //   name: "Artificial Intelligence - 1.2",
    //   venue: "LA 002",
    //   date: "18th & 19th December,2024",
    //   map: "https://maps.app.goo.gl/j9thgxyKJ2XbHc6x9"
    // },
    {
      id: 12,
      name: "Ethical Hacking",
      venue: "LA 202",
      date: "18th & 19th December,2024",
      map: "https://maps.app.goo.gl/j9thgxyKJ2XbHc6x9"
    },
    {
      id: 13,
      name: "Prompt Engineering",
      venue: "LH 101",
      date: "16th & 17th December,2024",
      map: "https://maps.app.goo.gl/eVihm3YZR1ikMGFC9"
    },
    {
      id: 14,
      name: "Data Science",
      venue: "LH 101",
      date: "18th & 19th December,2024",
      map: "https://maps.app.goo.gl/eVihm3YZR1ikMGFC9"
    },
    {
      id: 15,
      name: "Equity Research",
      venue: "LH 102",
      date: "17th December,2024",
      map: "https://maps.app.goo.gl/eVihm3YZR1ikMGFC9"
    },
    {
      id: 16,
      name: "Business Analytics",
      venue: "LH 102",
      date: "19th December,2024",
      map: "https://maps.app.goo.gl/eVihm3YZR1ikMGFC9"
    },
    {
      id: 17,
      name: "6th Sense Robotics",
      venue: "LH 301",
      date: "18th & 19th December,2024",
      map: "https://maps.app.goo.gl/eVihm3YZR1ikMGFC9"
    },
    {
      id: 18,
      name: "Gesture Robotics",
      venue: "LH 301",
      date: "18th & 19th December,2024",
      map: "https://maps.app.goo.gl/eVihm3YZR1ikMGFC9"
    },
    {
      id: 19,
      name: "Quadcopter",
      venue: "LH 302",
      date: "16th & 17th December,2024",
      map: "https://maps.app.goo.gl/eVihm3YZR1ikMGFC9"
    },
    {
      id: 20,
      name: "Python",
      venue: "LC 001",
      date: "16th & 17th December,2024",
      map: "https://maps.app.goo.gl/eVihm3YZR1ikMGFC9"
    },
    {
      id: 21,
      name: "Game Development",
      venue: "LC 001",
      date: "16th & 17th December,2024",
      map: "https://maps.app.goo.gl/eVihm3YZR1ikMGFC9"
    },
    {
      id: 22,
      name: "Fintech Summits",
      venue: "LC 002",
      date: "17th, 18th & 19th December,2024",
      map: "https://maps.app.goo.gl/eVihm3YZR1ikMGFC9"
    },
    {
      id: 23,
      name: "Cloud Comouting",
      venue: "LC 101",
      date: "16th & 17th December,2024",
      map: "https://maps.app.goo.gl/eVihm3YZR1ikMGFC9"
    },
    {
      id: 24,
      name: "Automobile EV",
      venue: "LC 101",
      date: "18th & 19th December,2024",
      map: "https://maps.app.goo.gl/eVihm3YZR1ikMGFC9"
    },
    {
      id: 25,
      name: "Cybersecurity",
      venue: "LC 102",
      date: "16th & 17th December,2024",
      map: "https://maps.app.goo.gl/eVihm3YZR1ikMGFC9"
    },
    {
      id: 26,
      name: "Quantum Computing",
      venue: "LC 102",
      date: "19th December,2024",
      map: "https://maps.app.goo.gl/eVihm3YZR1ikMGFC9"
    },
    {
      id: 27,
      name: "Digital Marketing",
      venue: "LC 201",
      date: "17th December,2024",
      map: "https://maps.app.goo.gl/eVihm3YZR1ikMGFC9"
    },
    {
      id: 28,
      name: "Solariser",
      venue: "LC 201",
      date: "19th December,2024",
      map: "https://maps.app.goo.gl/eVihm3YZR1ikMGFC9"
    },
    {
      id: 29,
      name: "Arduino",
      venue: "LC 202",
      date: "16th & 17th December,2024",
      map: "https://maps.app.goo.gl/eVihm3YZR1ikMGFC9"
    },
    {
      id: 30,
      name: "Java",
      venue: "LT 003",
      date: "16th & 17th December,2024",
      map: "https://maps.app.goo.gl/eVihm3YZR1ikMGFC9"
    },
    {
      id: 31,
      name: "Artificial Intelligence Two",
      venue: "LC 302",
      date: "16th & 17th December,2024",
      map: "https://maps.app.goo.gl/eVihm3YZR1ikMGFC9"
    },
    {
      id: 32,
      name: "Android Development",
      venue: "LC 302",
      date: "18th & 19th December,2024",
      map: "https://maps.app.goo.gl/eVihm3YZR1ikMGFC9"
    },
    {
      id: 33,
      name: "Blockchain",
      venue: "IC 1",
      date: "16th & 17th December,2024",
      map: "https://maps.app.goo.gl/fCUNtpMJXRUbavTc6"
    },
    {
      id: 34,
      name: "Web 3.0",
      venue: "IC 1",
      date: "18th December,2024",
      map: "https://maps.app.goo.gl/fCUNtpMJXRUbavTc6"
    },
    {
      id: 35,
      name: "Technical Analysis",
      venue: "IC 2",
      date: "18th December,2024",
      map: "https://maps.app.goo.gl/fCUNtpMJXRUbavTc6"
    },
    {
      id: 40,
      name: "Robowars",
      venue: "SAC (Student Activity Centre)",
      date: "17th and 18th December,2024",
      map: "https://maps.app.goo.gl/fCUNtpMJXRUbavTc6"
    },
  ];

  const { user } = UserAuth();
  const [passType, setPassType] = useState("common");
  const [myWorkshops, setMyWorkshops] = useState([]);
  const [activeTicket, setActiveTicket] = useState(null);

  const targetEmail = user?.email?.toLowerCase().trim();

  useEffect(() => {
    const checkEmailInCsv = async () => {
      try {
        const fetchCsv = async (filePath) => {
          const response = await fetch(filePath);
          if (!response.ok) throw new Error(`Failed to fetch CSV file: ${filePath}`);
          return response.text();
        };

        const parseCsv = (csvText) =>
          new Promise((resolve, reject) => {
            Papa.parse(csvText, {
              header: true,
              skipEmptyLines: true,
              complete: (result) => resolve(result.data),
              error: reject,
            });
          });

        const [vipText, workaccoText] = await Promise.all([
          fetchCsv("/vipxy.csv"),
          fetchCsv("/workix.csv"),
        ]);

        const [vipData, workaccoData] = await Promise.all([
          parseCsv(vipText),
          parseCsv(workaccoText),
        ]);

        const vipEmails = vipData.map((entry) => entry.Email?.toLowerCase().trim());
        const workaccoEmails = workaccoData.map((entry) => entry.Email?.toLowerCase().trim());

        if (vipEmails.includes(targetEmail)) setPassType("vip");
        else if (workaccoEmails.includes(targetEmail)) setPassType("workacco");
      } catch (error) {
        console.error("Error processing CSV files:", error);
      }
    };

    if (targetEmail) checkEmailInCsv();
  }, [targetEmail]);

  const loadCSV = async (path) => {
    try {
      const response = await fetch(path);
      if (!response.ok) {
        throw new Error(`Failed to fetch CSV file: ${response.statusText}`);
      }

      const csvText = await response.text();
      const rows = csvText.trim().split("\n");
      const headers = rows[0].split(",");

      const data = rows.slice(1).map((row) => {
        const values = row.split(",");
        return headers.reduce((acc, header, index) => {
          acc[header.trim()] = values[index]?.trim();
          return acc;
        }, {});
      });

      return data;
    } catch (error) {
      console.error("Error loading CSV:", error);
      return [];
    }
  };

  const findWorkshops = async (email) => {
    const csvData = await loadCSV("/workix.csv");
    const workshops = csvData
      .filter((entry) => entry.email === email)
      .map((entry) => entry.workshop);
    return workshops;
  };

  useEffect(() => {
    const fetchWorkshops = async () => {
      if (!targetEmail) return;
      try {
        const fetchedWorkshops = await findWorkshops(targetEmail);
        // setWorkshops(fetchedWorkshops);

        // Map fetched workshops to masterWorkshops
        const userWorkshops = fetchedWorkshops.map((workshopName) =>
          masterWorkshops.find((myWorkshop) => myWorkshop.name === workshopName)
        ).filter(Boolean); // Exclude undefined matches
        setMyWorkshops(userWorkshops);
      } catch (error) {
        console.error("Error fetching workshops:", error);
      }
    };

    fetchWorkshops();

    
  }, [targetEmail, masterWorkshops]);


  const handleTicketClick = (ticketId) => {
    setActiveTicket((prev) => (prev === ticketId ? null : ticketId));
  };

  const generateQrData = (eventName) => {
    return `Email: ${targetEmail}\nPass Type: ${passType}\nEvent: ${eventName}`;
  };

  return (
    <div className={styles.passpage}>
  <div className={styles.container}>
    {events.map((ticket) => (
      <div
        key={ticket.id}
        className={styles.ticketcontainer}
      >
        <div
          className={`${styles.ticket} ${
          passType === "vip" ? styles.vipContainer : ""
        }`}
          onClick={() => handleTicketClick(ticket.id)}
        >
          <p className={styles.name}>{ticket.name}</p>
          <img src={down} className={styles.arrow} alt="arrow" />
        </div>
        <div
          className={`${styles.pass} ${
            activeTicket === ticket.id ? styles.slideout : ""
          } ${passType === "vip" ? styles.vipPass : ""}`}
        >
          <div className={styles.qrcontainer}>
            {passType !== "vip" && (
              <div className={styles.qrCode}>
                <QRCodeCanvas
                  value={generateQrData(ticket.name)}
                  size={150}
                  bgColor="#ffffff"
                  fgColor="#000000"
                />
              </div>
            )}
          </div>
          <div className={styles.infocontainer}>
            <img src={logo} alt="Event Logo" />
            <p className={styles.ticketname}>{ticket.name.toUpperCase()}</p>
            <h1 className={styles.passType}>
              {passType === "vip"
                ? "VIP Pass"
                : passType === "workacco"
                ? "Premium Pass"
                : "Common Pass"}
            </h1>
            <p className={styles.passtext}>Email: {targetEmail}</p>
            <p className={styles.passtext}>Date: {ticket.date}</p>
            <p className={styles.passtext}>Venue: {ticket.venue}</p>
            <div className={styles.map}>
              <a href={ticket.map}>See location on map</a>
            </div>
          </div>
        </div>
      </div>
    ))}
    

        {myWorkshops.map((workshop) => (
          <div key={workshop.id} className={styles.ticketcontainer}>
            <div
              className={styles.ticket}
              onClick={() => handleTicketClick(workshop.id)}
            >
              <p className={styles.name}>{workshop.name}</p>
              <img src={down} className={styles.arrow} alt="arrow" />
            </div>
            <div
              className={`${styles.pass} ${
                activeTicket === workshop.id ? styles.slideout : ""
              }`}
            >
              <div className={styles.qrcontainer}>
                <div className={styles.qrCode}>
                  <QRCodeCanvas
                    value={generateQrData(workshop.name)}
                    size={150}
                    bgColor="#ffffff"
                    fgColor="#000000"
                  />
                </div>
              </div>
              <div className={styles.infocontainer}>
                <img src={logo}></img>
                <p className={styles.ticketname}>{workshop.name.toUpperCase()}</p>
                <h1 className={styles.passType}>
                  Workshop Pass
                </h1>
                <p className={styles.passtext}>Email: {targetEmail}</p>
                <p className={styles.passtext}>Date: {workshop.date}</p>
                <p className={styles.passtext}>Venue: {workshop.venue}</p>
              </div>
            </div>
          </div>
        ))}
        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
      </div>
    </div>
  );
};

export default Passes;
