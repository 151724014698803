import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserAuth } from '../../context/AuthContext'; // Adjust the path according to your project structure
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './signin.module.css'; // Add your custom styles

const SignIn = () => {
  const { user, googleSignIn } = UserAuth();
  const navigate = useNavigate();

  // Navigate after user signs in
  useEffect(() => {
    if (user) {
      navigate('/passes');
    }
  }, [user, navigate]);

  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn();
      toast.success('Successfully signed in!');
    } catch (error) {
      console.error(error);
      toast.error('Failed to sign in. Please try again.');
    }
  };

  return (
    <div className={styles.signinContainer}>
      <ToastContainer />
      <div>
        <h1 style={{ color: '#333', marginBottom: '1rem', fontSize: '2rem', fontWeight: 'bold' }}>
          Please Sign In To Continue
        </h1>
        <button onClick={handleGoogleSignIn} className={styles.signinButton}>
          Sign In with Google
        </button>
      </div>
    </div>
  );
};

export default SignIn;
