// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { UserAuth } from '../../context/AuthContext';
// import styles from './profile.module.css';
// import bg from '../Home/assets/profile_bg2.jpg';

// const Profile = () => {
//   axios.defaults.xsrfCookieName = "csrftoken";
//   axios.defaults.xsrfHeaderName = "X-CSRFToken";
//   const { user } = UserAuth();

//   const [profileData, setProfileData] = useState({ competitions: [], workshops: [], accos: []});
//   const [isLoading, setIsLoading] = useState(true);

//   const email = user?.email;

//   useEffect(() => {
//     const fetchProfileData = async () => {
//       try {
//         const [competitionsResponse, workshopsResponse, accosResponse] = await Promise.all([
//           axios.get("https://techfest.org/api/profile_compi/", {
//             headers: {
//               "X-Google-UID": user?.uid,
//             },
//           }),
//           axios.get("https://techfest.org/api/profile_workshop/", {
//             headers: {
//               "Email": email,
//             },
//           }),
//           axios.get("https://techfest.org/api/profile_acco/", {
//             headers: {
//               "Email": email,
//             },
//           }),
//         ]);

//         if (competitionsResponse.status === 200 && workshopsResponse.status === 200 && accosResponse.status===200) {
//           setProfileData({
//             competitions: competitionsResponse.data.competitions,
//             workshops: workshopsResponse.data.workshops,
//             accos: accosResponse.data.accos,
//           });
//         } else {
//           console.error("Error:", competitionsResponse.status, workshopsResponse.status,accosResponse.status);
//         }
//       } catch (error) {
//         console.error("Error:", error);
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     if (email) {
//       fetchProfileData();
//     }
//   }, [email, user?.uid]);

//   if (isLoading) {
//     return <div className={styles.loading}>Loading...</div>;
//   }

//   const bgHaiJi = {
//     backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${bg})`,
//     backgroundSize: "cover",
//     backgroundRepeat: "no-repeat",
//     backgroundColor:'white',
//     backgroundPosition: "center center",
//     width: "100vw",
//     position: "absolute",
//     top: "0",
//     zIndex: "-1",
//     backgroundAttachment: "fixed",
//   };

//   return (
//     <div>
//     <div className={styles.profile} style={bgHaiJi}>
//       <div className={styles.profilearrange}>
//       <div className={styles.profileHeader}>
//         <div className={styles.profilePhoto}>
//           {user?.photoURL ? (
//             <img src={user.photoURL} alt="Profile" />
//           ) : (
//             <p>No Profile Image</p>
//           )}
//         </div>
//         <div className={styles.profileInfo}>
//           <h2 style={{color:'white',fontFamily:'Bruno Ace'}}>{user?.displayName}</h2>
//           <p style={{color:"white",fontFamily:'Bruno Ace'}}>{user?.email}</p>
//           <h4>Accommodation Details</h4>
//           <p style={{ color: "white", fontFamily: 'Bruno Ace' }}>
//                 {/* Display TFIDs */}
//                 Accommodation ID :  
//                 {profileData.accos.length > 0 ? (
//                   profileData.accos.map((acco, index) => (
//                     <span key={index}> {acco.tfid}{index < profileData.accos.length - 1 ? ', ' : ''}</span>
//                   ))
//                 ) : (
//                   "No accommodation registered."
//                 )}

//               </p>
//               <p style={{ color: "white", fontFamily: 'Bruno Ace' }}>
//                 {/* Display TFIDs */} 
//                 {profileData.accos.length > 0 ? (
//                     <a href="https://konfhub.com/hospitality" target="_blank" ><div className={styles.himanshu}><h1>Pay Now</h1></div></a>
                  
//                 ) : (
//                   <a href="https://techfest.org/accommodation" target="_blank" ><div className={styles.himanshu}><h1>Register Now</h1></div></a>

//                 )}
                
//               </p>
//         </div>
//       </div>
//       <div className={styles.profileContent}>
//         <h3>Registrations</h3>
//         <div className={styles.compiwork}>
//           <div className={styles.compi}>
//         <h4>Competitions:</h4>
//         <div className={styles.registrations}>
//           {profileData.competitions.length > 0 ? (
//             profileData.competitions.map((competition) => (
//               <div key={competition.id} className={styles.competition}>
//                 <h5>{competition.name}</h5>
//                 {/* <p>Max Team Length: {competition.max_team_length}</p> */}
//                 <p>Prize: {competition.prize}</p>
//                 {competition.img && <img src={competition.img} alt={competition.name} />}
//                 {competition.statement && (
//                   <a href={competition.statement} target="_blank" rel="noopener noreferrer">
//                     Statement
//                   </a>
//                 )}
//                 {competition.sponsorImg && <img src={competition.sponsorImg} alt="Sponsor" />}
//               </div>
//             ))
//           ) : (
//             <p>No competitions registered.</p>
//           )}
//         </div>
//         </div>
//         <div className={styles.work}>
//         <h4>Workshops:</h4>
//         <div className={styles.registrations}>
//           {profileData.workshops.length > 0 ? (
//             profileData.workshops.map((workshop) => (
//               <div key={workshop.id} className={styles.workshop}>
//                 <h5>{workshop.name}</h5>
//                 {/* <p>Description: {workshop.desc}</p> */}
//                 <p>Prize: {workshop.prize}</p>
//                 {/* <p>Extended Prize: {workshop.ex_prize}</p> */}
//                 {workshop.img && <img src={workshop.img} alt={workshop.name} />}
//                 {workshop.statement && (
//                   <a href={workshop.statement} target="_blank" rel="noopener noreferrer">
//                     Statement
//                   </a>
//                 )}
//                 {/* {workshop.sponsorImg && <img src={workshop.sponsorImg} alt="Sponsor" />} */}
//                 {/* <p>Payment Link: {workshop.paymentLink}</p> */}
//                 {/* <p>Closed: {workshop.closed ? "Yes" : "No"}</p> */}
//               </div>
//             ))
//           ) : (
//             <p>No workshops registered.</p>
//           )}
//         </div>
//         </div>
//         </div>
//       </div>
//     </div>
//     </div>
//     </div>
//   );
// };

// export default Profile;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Papa from 'papaparse'; // For parsing CSV files
import { UserAuth } from '../../context/AuthContext';
import styles from './profile.module.css';
import bg from '../Home/assets/profile_bg2.jpg';

const Profile = () => {
  axios.defaults.xsrfCookieName = "csrftoken";
  axios.defaults.xsrfHeaderName = "X-CSRFToken";
  const { user } = UserAuth();

  const [profileData, setProfileData] = useState({ competitions: [], workshops: [], accos: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [roomData, setRoomData] = useState([]);
  const [roomDetails, setRoomDetails] = useState(null);

  const email = user?.email;

  // Fetch API data
  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const [competitionsResponse, workshopsResponse, accosResponse] = await Promise.all([
          axios.get("https://techfest.org/api/profile_compi/", {
            headers: {
              "X-Google-UID": user?.uid,
            },
          }),
          axios.get("https://techfest.org/api/profile_workshop/", {
            headers: {
              "Email": email,
            },
          }),
          axios.get("https://techfest.org/api/profile_acco/", {
            headers: {
              "Email": email,
            },
          }),
        ]);

        if (
          competitionsResponse.status === 200 &&
          workshopsResponse.status === 200 &&
          accosResponse.status === 200
        ) {
          setProfileData({
            competitions: competitionsResponse.data.competitions,
            workshops: workshopsResponse.data.workshops,
            accos: accosResponse.data.accos,
          });
        } else {
          // console.error("Error:", competitionsResponse.status, workshopsResponse.status, accosResponse.status);
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (email) {
      fetchProfileData();
    }
  }, [email, user?.uid]);

  // Fetch and parse hospi.csv
  useEffect(() => {
    const fetchRoomData = async () => {
      try {
        const response = await fetch('/acco.csv'); // Ensure hospi.csv is placed in the public directory
        const csvText = await response.text();

        Papa.parse(csvText, {
          header: true,
          complete: (result) => {
            // console.log('CSV data:', result.data);  // Debugging: Log parsed CSV data
            setRoomData(result.data);
          },
          error: (error) => {
            console.error('Error parsing CSV:', error);
          },
        });
      } catch (error) {
        console.error('Error fetching CSV:', error);
      }
    };

    fetchRoomData();
  }, []);

  // Filter room details based on email
  useEffect(() => {
    if (roomData.length > 0 && email) {
      // console.log('Filtered room data:', roomData);  // Log filtered room data
  
      const userRooms = roomData.filter((row) => row.Email === email);
  
      // console.log('User rooms:', userRooms);  // Log filtered user rooms
  
      if (userRooms.length > 0) {
        const roomDetails = userRooms.reduce((acc, row) => {
          const roomNumber = row["Room Number"];  // Correct way to access 'Room Number'
          const password = row.Password;
          const hostel = row.Hostel;
  
          // Log to check roomNumber
          // console.log('Room number:', roomNumber);
  
          // Validate roomNumber before adding it to the acc.rooms object
          if (roomNumber) {
            acc.rooms[roomNumber] = acc.rooms[roomNumber] || { count: 0, password, hostel };
            acc.rooms[roomNumber].count += 1;
          } else {
            console.warn('Missing room number for row:', row); // Log if roomNumber is missing or invalid
          }
  
          return acc;
        }, { rooms: {} });
  
        // console.log('Aggregated room details:', roomDetails);  // Log the aggregated room details
  
        setRoomDetails(roomDetails.rooms);
      }
    }
  }, [roomData, email]);
  
  if (isLoading) {
    return <div className={styles.loading}>Loading...</div>;
  }

  const bgHaiJi = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${bg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundColor: 'white',
    backgroundPosition: "center center",
    width: "100vw",
    position: "absolute",
    top: "0",
    zIndex: "-1",
    backgroundAttachment: "fixed",
  };

  return (
    <div>
      <div className={styles.profile} style={bgHaiJi}>
        <div className={styles.profilearrange}>
          <div className={styles.profileHeader}>
            <div className={styles.profilePhoto}>
              {user?.photoURL ? (
                <img src={user.photoURL} alt="Profile" />
              ) : (
                <p>No Profile Image</p>
              )}
            </div>
            <div className={styles.profileInfo}>
              <h2 style={{ color: 'white', fontFamily: 'Bruno Ace' }}>{user?.displayName}</h2>
              <p style={{ color: "white", fontFamily: 'Bruno Ace' }}>{user?.email}</p>
              <h4>Accommodation Details</h4>
              <p style={{ color: "white", fontFamily: 'Bruno Ace' }}>
                Accommodation ID :
                {profileData.accos.length > 0 ? (
                  profileData.accos.map((acco, index) => (
                    <span key={index}> {acco.tfid}{index < profileData.accos.length - 1 ? ', ' : ''}</span>
                  ))
                ) : (
                  "No accommodation registered."
                )}
              </p>
              <h1>SOLD OUT ACCOMMODATION</h1>
              {/* <p style={{ color: "white", fontFamily: 'Bruno Ace' }}>
                {profileData.accos.length > 0 ? (
                  <a href="https://konfhub.com/hospitality" target="_blank"><div className={styles.himanshu}><h1>Pay Now</h1></div></a>
                ) : (
                  <a href="https://techfest.org/accommodation" target="_blank"><div className={styles.himanshu}><h1>Register Now</h1></div></a>
                )}
              </p> */}
              <h4>Room Details:</h4>
              <div className={styles.roomContainer}>
              {roomDetails ? (
                Object.entries(roomDetails).map(([roomNumber, details]) => (
                  <div key={roomNumber} className={styles.roomDetails}>
                    <p>Room Number: {roomNumber}</p>
                    <p>Room Password: {details.password}</p>
                    <p>No. of People: {details.count}</p>
                    <p>Hostel: {details.hostel}</p>
                  </div>
                ))
              ) : (
                <p style={{ color: "white", fontFamily: 'Bruno Ace' }}>No room allocation found.</p>
              )}
              </div>
            </div>
          </div>
          <div className={styles.profileContent}>
            <h3>Registrations</h3>
            <div className={styles.compiwork}>
              <div className={styles.compi}>
                <h4>Competitions:</h4>
                <div className={styles.registrations}>
                  {profileData.competitions.length > 0 ? (
                    profileData.competitions.map((competition) => (
                      <div key={competition.id} className={styles.competition}>
                        <h5>{competition.name}</h5>
                        <p>Prize: {competition.prize}</p>
                        {competition.img && <img src={competition.img} alt={competition.name} />}
                        {competition.statement && (
                          <a href={competition.statement} target="_blank" rel="noopener noreferrer">
                            Statement
                          </a>
                        )}
                        {competition.sponsorImg && <img src={competition.sponsorImg} alt="Sponsor" />}
                      </div>
                    ))
                  ) : (
                    <p>No competitions registered.</p>
                  )}
                </div>
              </div>
              <div className={styles.work}>
                <h4>Workshops:</h4>
                <div className={styles.registrations}>
                  {profileData.workshops.length > 0 ? (
                    profileData.workshops.map((workshop) => (
                      <div key={workshop.id} className={styles.workshop}>
                        <h5>{workshop.name}</h5>
                        <p>Prize: {workshop.prize}</p>
                        {workshop.img && <img src={workshop.img} alt={workshop.name} />}
                        {workshop.statement && (
                          <a href={workshop.statement} target="_blank" rel="noopener noreferrer">
                            Statement
                          </a>
                        )}
                      </div>
                    ))
                  ) : (
                    <p>No workshops registered.</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;




